var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header", { attrs: { title: "亚洲分期", back: true } }),
      _c(
        "div",
        { staticClass: "tab-box" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.stagesClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "3月期", name: "3" } }),
              _c("el-tab-pane", { attrs: { label: "6月期", name: "6" } }),
              _c("el-tab-pane", { attrs: { label: "12月期", name: "12" } }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "tab-content-box" }, [
        _c("div", { staticClass: "three-box" }, [
          _c("div", { staticClass: "box-items" }, [
            _c("div", { staticClass: "tip-rate-text" }, [
              _vm._v("预期年化收益率"),
            ]),
            _c("div", { staticClass: "flex-box box-num box-percent" }, [
              _c("div", [_vm._v(_vm._s(_vm.rate))]),
              _c("div", { staticClass: "percent" }, [_vm._v("%")]),
            ]),
            _c("div", { staticClass: "tag-box" }, [
              _c("div", { staticClass: "theme-subbjcolor" }, [
                _vm._v("锁定" + _vm._s(_vm.days) + "天"),
              ]),
              _c("div", { staticClass: "theme-subbjcolor" }, [
                _vm._v("$" + _vm._s(_vm._f("format")(_vm.moniesMin)) + "起投 "),
              ]),
              _c("div", { staticClass: "theme-subbjcolor" }, [
                _vm._v("中低风险 "),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "defined-btn", on: { click: _vm.nowBuy } },
              [_vm._v("立即购买")]
            ),
          ]),
          _c("div", { staticClass: "title" }, [_vm._v("预期收益")]),
          _c("div", { staticClass: "box-items income" }, [
            _c("div", { staticClass: "flex-box" }, [
              _c(
                "div",
                { staticClass: "add-btn", on: { click: _vm.handerDown } },
                [_vm._v("-")]
              ),
              _c("div", { staticClass: "add-input flex-box" }, [
                _c(
                  "div",
                  [
                    _c("el-input", {
                      on: { input: _vm.changeMonies },
                      model: {
                        value: _vm.monies1,
                        callback: function ($$v) {
                          _vm.monies1 = $$v
                        },
                        expression: "monies1",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "add-btn", on: { click: _vm.handerUp } },
                [_vm._v("+")]
              ),
            ]),
            _c("div", { staticClass: "income-add" }, [
              _vm._v("预估收益："),
              _c("span", [_vm._v("$" + _vm._s(_vm.shouyi))]),
            ]),
          ]),
          _c("div", { staticClass: "title" }, [_vm._v("交易规则")]),
          _c("div", { staticClass: "flex-box rule box-items" }, [
            _vm._m(0),
            _c("div", { staticClass: "rule-middle" }, [
              _c("div", { staticClass: "rule-title" }, [
                _vm._v("亚洲分期-" + _vm._s(_vm.activeName) + "月"),
              ]),
              _c("div", { staticClass: "line-middle" }),
              _c("div", { staticClass: "rule-border" }),
              _c("div", { staticClass: "rule-sub-title" }, [
                _vm._v(_vm._s(_vm.interestDay())),
              ]),
              _c("div", { staticClass: "rule-sub-title" }, [
                _vm._v("成交计息"),
              ]),
            ]),
            _c("div", { staticClass: "rule-right" }, [
              _c("div", { staticClass: "rule-title" }, [_vm._v("续投/转出")]),
              _c("div", { staticClass: "line-right" }),
              _c("div", { staticClass: "rule-border" }),
              _c("div", { staticClass: "rule-sub-title" }, [
                _vm._v(_vm._s(_vm.addDays(_vm.days))),
              ]),
              _c("div", { staticClass: "rule-sub-title" }, [_vm._v("到期")]),
            ]),
          ]),
          _c("div", { staticClass: "buy box-items" }, [
            _c(
              "div",
              { staticClass: "tab-box2" },
              [
                _c(
                  "el-tabs",
                  {
                    model: {
                      value: _vm.activeName2,
                      callback: function ($$v) {
                        _vm.activeName2 = $$v
                      },
                      expression: "activeName2",
                    },
                  },
                  [
                    _c("el-tab-pane", { attrs: { label: "买入", name: "ru" } }),
                    _c("el-tab-pane", {
                      attrs: { label: "转出", name: "chu" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.activeName2 == "ru"
              ? _c("div", { staticClass: "ru" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "flex-box" }, [
                    _c("div", [_vm._v("锁定期限")]),
                    _c("div", [_vm._v(_vm._s(_vm.days) + "天")]),
                  ]),
                  _vm._m(2),
                ])
              : _vm._e(),
            _vm.activeName2 == "chu"
              ? _c("div", { staticClass: "chu" }, [
                  _c("table", { staticClass: "chu-table" }, [
                    _vm._m(3),
                    _c("tr", [
                      _c("td", [_vm._v("提前转出")]),
                      _c("td", [_vm._v("到期前任意时间")]),
                      _c("td", [
                        _vm._v(
                          "(本金+收益)*" + _vm._s(_vm.sellrate * 100) + "%"
                        ),
                      ]),
                    ]),
                    _vm._m(4),
                  ]),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "ru-more theme-color",
                on: { click: _vm.handerRules },
              },
              [_vm._v("查看详细规则 >")]
            ),
          ]),
          _c("div", { staticClass: "title" }, [_vm._v("产品亮点")]),
          _vm._m(5),
          _c("div", { staticClass: "title" }, [_vm._v("底层资产")]),
          _c(
            "div",
            {
              staticClass: "bottom-mone box-items",
              on: { click: _vm.stagesMore },
            },
            [
              _c("img", {
                staticStyle: { width: "20%" },
                attrs: { src: require("@/assets/img/akulaku.png") },
              }),
              _c("div", { staticClass: "ru-more theme-color" }, [
                _vm._v("了解更多 >"),
              ]),
            ]
          ),
          _vm._m(6),
        ]),
      ]),
      _vm.fixed
        ? _c("div", { staticClass: "footer-btn" }, [
            _c(
              "button",
              { staticClass: "defined-btn", on: { click: _vm.nowBuy } },
              [_vm._v("立即购买")]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "rule-left" }, [
      _c("div", { staticClass: "rule-title" }, [_vm._v("预约")]),
      _c("div", { staticClass: "line-left" }),
      _c("div", { staticClass: "rule-border" }),
      _c("div", { staticClass: "rule-sub-title" }, [_vm._v("今日")]),
      _c("div", { staticClass: "rule-sub-title" }, [_vm._v("预约买入")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-box" }, [
      _c("div", [_vm._v("投资限额")]),
      _c("div", [_vm._v("起投$1,000，投资限额$200,000")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-box" }, [
      _c("div", [_vm._v("成交计息")]),
      _c("div", [
        _vm._v("任意时间买入，每周四固定成交，成交当天开始计息，T+1收益到账"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("转出方式")]),
      _c("td", [_vm._v("限制条件")]),
      _c("td", [_vm._v("手续费")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("到期转出")]),
      _c("td", [_vm._v("到期后自动转出")]),
      _c("td", [_vm._v("无")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-box highlights box-items" }, [
      _c("div", [
        _c("img", { attrs: { src: require("@/assets/icon/l1.png") } }),
        _c("div", [_vm._v("资产优质")]),
        _c("div", { staticClass: "subtext" }, [
          _vm._v("精选东南亚消费分期"),
          _c("br"),
          _vm._v("资金分散、风险风控"),
        ]),
      ]),
      _c("div", [
        _c("img", { attrs: { src: require("@/assets/icon/l2.png") } }),
        _c("div", [_vm._v("合规经营")]),
        _c("div", { staticClass: "subtext" }, [
          _vm._v("持有牌照，合法运营"),
          _c("br"),
          _vm._v("受印尼金融管理局监管"),
        ]),
      ]),
      _c("div", [
        _c("img", { attrs: { src: require("@/assets/icon/l3.png") } }),
        _c("div", [_vm._v("全程风控")]),
        _c("div", { staticClass: "subtext" }, [
          _vm._v("智能大数据风控系统"),
          _c("br"),
          _vm._v("保障债权安全"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "gray-text" }, [
      _vm._v(" 本页面非任何法律文件，投资前请认真阅读产品投资协议。"),
      _c("br"),
      _vm._v("本页中展示收益率不代表收益承诺。市场有风险，投资需谨慎。 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <Header :title="'亚洲分期'" :back="true"/>
    <div class="tab-box">
      <el-tabs v-model="activeName" @tab-click="stagesClick">
        <el-tab-pane label="3月期" name="3"></el-tab-pane>
        <el-tab-pane label="6月期" name="6"></el-tab-pane>
        <el-tab-pane label="12月期" name="12"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="tab-content-box">
      <div class="three-box">
        <div class="box-items">
          <div class="tip-rate-text">预期年化收益率</div>
          <div class="flex-box box-num box-percent">
            <div>{{rate}}</div>
            <div class="percent">%</div>
          </div>
          <div class="tag-box">
            <div class="theme-subbjcolor">锁定{{ days }}天</div>
            <div class="theme-subbjcolor">${{ moniesMin |format}}起投 </div>
            <div class="theme-subbjcolor">中低风险 </div>
          </div>
          <div class="defined-btn" @click="nowBuy">立即购买</div>
        </div>
        <div class="title">预期收益</div>
        <div class="box-items income">
          <div class="flex-box">
            <div class="add-btn" @click="handerDown">-</div>
            <div class="add-input flex-box">
              <div ><el-input v-model="monies1" @input="changeMonies"></el-input></div>
            </div>
            <div class="add-btn" @click="handerUp">+</div>
          </div>
          <div class="income-add">预估收益：<span>${{shouyi}}</span>
          </div>
        </div>
        <div class="title">交易规则</div>
        <div class="flex-box rule box-items">
          <div class="rule-left">
            <div class="rule-title">预约</div>
            <div class="line-left"></div>
            <div class="rule-border"></div>
            <div class="rule-sub-title">今日</div>
            <div class="rule-sub-title">预约买入</div>
          </div>
          <div class="rule-middle">
            <div class="rule-title">亚洲分期-{{activeName}}月</div>
            <div class="line-middle"></div>
            <div class="rule-border"></div>
            <div class="rule-sub-title">{{interestDay()}}</div>
            <div class="rule-sub-title">成交计息</div>
          </div>
          <div class="rule-right">
             <div class="rule-title">续投/转出</div>
            <div class="line-right"></div>
            <div class="rule-border"></div>
            <div class="rule-sub-title">{{addDays(days)}}</div>
            <div class="rule-sub-title">到期</div>
          </div>
        </div>
        <div class="buy box-items">
          <div class="tab-box2">
            <el-tabs v-model="activeName2">
              <el-tab-pane label="买入" name="ru"></el-tab-pane>
              <el-tab-pane label="转出" name="chu"></el-tab-pane>
            </el-tabs>
          </div>
          <div class="ru" v-if="activeName2=='ru'">
            <div class="flex-box">
              <div>投资限额</div>
              <div>起投$1,000，投资限额$200,000</div>
            </div>
            <div class="flex-box">
              <div>锁定期限</div>
              <div>{{days}}天</div>
            </div>
            <div class="flex-box">
              <div>成交计息</div>
              <div>任意时间买入，每周四固定成交，成交当天开始计息，T+1收益到账</div>
            </div>
          </div>
          <div class="chu" v-if="activeName2=='chu'">
            <table class="chu-table">
              <tr>
                <td>转出方式</td>
                <td>限制条件</td>
                <td>手续费</td>
              </tr>
              <tr>
                <td>提前转出</td>
                <td>到期前任意时间</td>
                <td>(本金+收益)*{{ sellrate*100 }}%</td>
              </tr>
              <tr>
                <td>到期转出</td>
                <td>到期后自动转出</td>
                <td>无</td>
              </tr>
            </table>
          </div>
          <div class="ru-more theme-color" @click="handerRules">查看详细规则 ></div>
        </div>
        <div class="title">产品亮点</div>
        <div class="flex-box highlights box-items">
          <div>
            <img src="@/assets/icon/l1.png"/>
            <div>资产优质</div>
            <div class="subtext">精选东南亚消费分期<br/>资金分散、风险风控</div>
          </div>
          <div>
            <img src="@/assets/icon/l2.png"/>
            <div>合规经营</div>
            <div class="subtext">持有牌照，合法运营<br/>受印尼金融管理局监管</div>
          </div>
          <div>
            <img src="@/assets/icon/l3.png"/>
            <div>全程风控</div>
            <div class="subtext">智能大数据风控系统<br/>保障债权安全</div>
          </div>
        </div> 
        <div class="title">底层资产</div>
        <div class="bottom-mone box-items" @click="stagesMore">
          <img style="width:20%" src="@/assets/img/akulaku.png"/>
          <div class="ru-more theme-color" >了解更多 ></div>
        </div>
        <div class="gray-text">
          本页面非任何法律文件，投资前请认真阅读产品投资协议。<br/>本页中展示收益率不代表收益承诺。市场有风险，投资需谨慎。
        </div>
      </div>
    </div>
    <div class="footer-btn" v-if="fixed">
      <button class="defined-btn" @click="nowBuy">立即购买</button>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  data(){
    return {
      numberPattern: '^\\d?$' ,// 正则表达式，限制小数点后最多两位
      fixed:false,
      activeName:'3',
      activeName2:"ru",
      rate:'5.0',
      days:'90',
      monies:10000,// 买入金额
      monies1:"$10,000",// 买入金额
      stagesList:'',
      moniesMin:1000,
      moniesMax:200000,
      sellrate:0.015
    }
  },
  computed:{
    // monies1:{
    //   set(val){
    //     console.log(val)
    //   },
    //   get(){
    //     let newpra=null
    //     if(this.monies<0){
    //       newpra = "$0"
    //     }else if(this.monies>20000){
    //       newpra = "$"+this.format(20000)
    //     }else{
    //       newpra = "$"+this.format(this.monies)
    //     }
    //      return newpra
    //   }
    // },
    shouyi:{
      set(val){
        console.log(val)
      },
      get(){
         return this.$options.filters.formatFloat(this.monies*this.rate*0.01/365*this.days)
      }
    }
  },
  
  created(){
    this.getStages()
  },
  mounted(){
    window.addEventListener('scroll',this.scrolling)
  },
  
  methods:{
    changeMonies(){
      this.monies1 = this.monies1.replace(/[^\d\\$]/g,'')
      if(!this.monies1){
        this.monies1="$"
      }
      let temp =  this.monies1
      if(temp.indexOf('$')!=-1){
        temp = temp.replace(/\$/g, '')
      }
      if(temp.indexOf(',')!=-1){
        temp = temp.replace(/,/g, '')
      }
      this.monies = Number(temp)
    },
    nowBuy(){
      this.$router.push({path:"buy",query:{periods:this.activeName,days:this.days}})
    },
    getStages(){
      this.$store.dispatch('stages/getIncome',{params:{product:'akulaku'}}).then(res => {
        this.stagesList = res.terms
        let arr = this.stagesList.filter(item=>item.term==this.activeName)
        this.rate= arr[0].rate
        this.days= arr[0].days
        this.monies= 10000
        this.monies1 = '$'+this.format(this.monies)
        this.moniesMin=arr[0].doorsill
        this.moniesMax=arr[0].max
        this.sellrate =arr[0].sellrate
      })
    },
    stagesClick(){
      let arr = this.stagesList.filter(item=>item.term==this.activeName)
      this.rate= arr[0].rate
      this.days= arr[0].days
      this.monies= 10000
      this.monies1 = '$'+this.format(this.monies)
      this.moniesMin=arr[0].doorsill
      this.moniesMax=arr[0].max
      this.sellrate =arr[0].sellrate
    },
    handerDown(){
      if(this.monies<=1000){
         this.monies =this.moniesMin
      }else{
         this.monies -=1000
      }
      this.monies1 = '$'+this.format(this.monies)
    },
    handerUp(){
       if(this.monies>=this.moniesMax){
        this.monies =this.moniesMax
      }else{
        this.monies +=1000
      }
      this.monies1 = '$'+this.format(this.monies)
    },
    // 查看更多规则
    handerRules(){
      this.$router.push({path:'rules',query:{periods:this.activeName}})
    },
    stagesMore(){
      this.$router.push({name:'stagesDetail'})
    },
   scrolling(){
    let scroll = window.pageYOffset || document.documentElement.scrollTop|| document.body.scrollTop
    if(scroll>300){
      this.fixed = true
    }else{
      this.fixed = false
    }
   }
  }
}
</script>
<style lang="scss" scoped>
.tab-content-box{
  background: #F8F8F8;
}
.tab-box{
  background: #fff;
   ::v-deep{
    .el-tabs{
      line-height:40px;
      margin-bottom:3%;
      .el-tabs__header{
        margin:0px;
      }
      .el-tabs__nav{
        width: 99%;
        margin-left:.5%
      }
      .el-tabs__item{
        width: 33.33%;
        // font-size:1rem;
        text-align: center;
      }
      .el-tabs__active-bar{
        // width: 10% !important;
        // height: 5px;
        // border-radius: 3px;
        margin-left:14%
      }
    }
    
   }

}
.tab-box2{
  background: #fff;
   ::v-deep{
    .el-tabs{
      // line-height:50px;
      // margin-bottom:3%;
      // .el-tabs__header{
      //   margin:0px;
      // }
      .el-tabs__nav{
        width: 99%;
        margin-left:.5%
      }
      .el-tabs__item{
        width: 50%;
        // font-size:1rem;
        text-align: center;
      }
      .el-tabs__active-bar{
        // width: 20px !important;
        // height: 5px;
        // border-radius: 3px;
        margin-left:22%
      }
    }
    
   }

}
.el-tabs__nav-wrap::after{
  border:none
}
.three-box{
  text-align: center;
  .flex-box{
    justify-content: center;
  }
  .box-num {
    font-size: 3rem;
    margin:10px;
    .percent{
      font-size: 1.8rem;
    }
  }
}
.el-tag{
  margin-right:2%;
  height:24px;
  line-height:22px;
  margin-bottom:25px;
}
.income{
  background: #fff;
  padding: 30px 0px;
  .add-btn{
    width: 40px;
    height: 35px;
    font-size: 26px;
    // line-height: 32px;
    // border:none;
    background: #fff;
    border-radius:4px;
    box-shadow: 0 0 0.2rem 0 rgba(0,0,0,.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .add-input{
    line-height: 2.2rem;
    margin: 0px .2rem;
    border-bottom:1px solid #ddd;
    width: 45%;
    justify-content: center;
    align-items: center;
    ::v-deep{
      .el-input__inner{
        border:none;
        text-align: center;
        color:#464646;
        font-size: 1.4rem;
        padding:0px;
      }
    }
  }
  .prefix-input{
    font-size: 1.4rem;
    color:#464646;
  }
  .income-add{
    margin-top:5%;
    text-align: center;
    color: #464646;
    font-size: .9rem;
  }
}
.rule{
  // width: 90%;
  padding: 5% 5% 4% 5%;
  background: #fff;
  .rule-title{
    line-height: 2;
    font-size: .9rem;
  }
  .rule-border{
    margin-top:4px;
    height: 8px;
    width: 18px;
    border-bottom:2px solid #d9d9d9;
    border-left:2px solid #d9d9d9;
  }
  .rule-sub-title{
    font-size: .8rem;
    line-height:1.2rem;
    text-align: left;
  }
  .rule-left{
    width: 24%;
    .line-left{
      width: 100%;
      height: 8px;
      border-radius: 4px 0px 0px 4px;
      background: -webkit-linear-gradient(left,#acacac,#e0e0e0);
      background: -o-linear-gradient(right,#acacac,#e0e0e0);
      background: -moz-linear-gradient(right,#acacac,#e0e0e0);
      background: linear-gradient(to right,#acacac,#e0e0e0);
    }
  }
  .rule-middle{
    width: 50%;
    margin:0px 2%;
    .line-middle{
      width: 100%;
      height: 8px;
      background: -webkit-linear-gradient(left,#f2545c,#ff7481);
      background: -o-linear-gradient(right,#f2545c,#ff7481);
      background: -moz-linear-gradient(right,#f2545c,#ff7481);
      background: linear-gradient(to right,#f2545c,#ff7481);
    }
  }
  .rule-right{
    width: 24%;
    .line-right{
      width: 100%;
      height: 8px;
      border-radius: 0px 4px 4px 0px;
      background: -webkit-linear-gradient(left,#d79877,#dbbfb5);
      background: -o-linear-gradient(right,#d79877,#dbbfb5);
      background: -moz-linear-gradient(right,#d79877,#dbbfb5);
      background: linear-gradient(to right,#d79877,#dbbfb5);
    }
  }
}
.highlights{
  justify-content: space-evenly !important ;
  background: #fff;
  padding: 6% 0%;
  margin-bottom:2.5%;
  img{
    height: 26px;
  }
  .subtext{
    margin-top:5px;
    font-size: .6rem;
    color:#464646
  }
}
.buy{
  background: #fff;
  margin-top:2.5%
}
.ru{
  .flex-box{
    padding:1% 2.5%;
    font-size: .9rem;
    text-align: left;
    justify-content: flex-start;
    width: 95%;
    >:first-child{
      width: 120px;
      flex-shrink: 0;
      color: #999;
      text-align: center;
    }
  }
}
.ru-more{
  line-height: 2rem;
  margin-top: 10px;
  font-size: 0.8rem;
  cursor: pointer;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
}

.gray-text{
  font-size: .7rem;
  color: #999;
  margin:3% auto;
  padding-bottom: 4rem;
}
.footer-btn{
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 95%;
  border-top:1px solid #eee;
  background: #ffffff;
  padding: 2.5%;
  text-align: center;
  .defined-btn{
    
    width: 96%;
   
  }
}
.tip-rate-text{
  color: #464646;
  font-size: .8rem;
}
.tag-box{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  >div{
    border-radius:12px;
    margin:5px 10px;
    color:#464646;
    padding:3px 14px;
    font-size: .6rem;
  }
}
</style>